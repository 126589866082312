
.un-paging-info {
    > ._label {
        @apply text-grey-blue-500;
    }

    > ._value {
        @apply text-red-500 mx-2 font-bold;
    }
}

.link-tel {
    @apply bg-blue-500 px-2 py-0.5 rounded-lg text-white text-sm cursor-pointer hover:bg-blue-600;
}

.text-header {
    @apply text-xl mb-4;
}

.table-stock-group {
    @apply py-1 px-4 bg-grey-blue-50 text-lg text-grey-blue-600;
}

.table-stock-sum {
    @apply bg-orange-50;
}


.detail-item {
    display: flex;
    margin-top: .25rem;
    margin-bottom: .25rem;

    &.-xs {
        > ._label {
            width: 50px;
        }
    }

    &.-sm {
        > ._label {
            width: 80px;
        }
    }

    &.-lg {
        > ._label {
            width: 220px;
        }
    }

    > ._label {
        width: 130px;
        min-width: 130px;
        text-align: right;
        padding-right: 1rem;
        color: $grey-blue;
    }

    > ._value {
        flex-grow: 1;
    }

    li + li {
        margin-top: .25rem;
    }
}


.dl-horizontal {
    dt {
        float: left;
        width: 120px;
        overflow: hidden;
        clear: left;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    dd {
        margin-left: 135px;
        margin-bottom: 8px;
        min-height: 20px;
    }
}


.w-dlt-device {
    @apply w-96;
    > ._chassis {
        @apply text-xs leading-tight;
    }
}

.w-device-status {
    @apply w-8 self-center;
    &._status {
        @apply h-8 flex items-center justify-center text-xs rounded-full;
        &.-status-0 {
            @apply bg-grey-blue-100 text-grey-blue-600;
        }

        &.-status-1 {
            @apply bg-blue-100 text-blue-600;
        }

        &.-status-2 {
            @apply bg-green-500 text-green-50;
        }

        &.-status-3 {
            @apply bg-red-500 text-red-50;
        }

        &.-status-4 {
            @apply bg-red-50 text-red-500;
        }
    }
}

.w-device-master {
    @apply w-52 text-right truncate;
    > ._time {
        @apply text-blue-500;
    }

    > ._log {
        @apply text-sm text-grey-blue-500 leading-none truncate;
    }
}

.w-datebox {
    @apply flex flex-col w-28 text-right;
    > ._time {
        @apply text-sm text-grey-blue-500 text-right;
    }

    > ._diff {
        @apply text-sm text-red-500 text-right;
    }
}

.w-datebox-inline {
    @apply w-52 text-right;
    &.-sm {
        @apply w-40;
    }

    > ._diff {
        @apply text-sm text-red-500 text-right mr-2;
    }
}


.search-popup-quotation {
    .un-input-search-row {
        @apply max-w-full;
    }
}

.longdo-map-container {
    @apply relative flex flex-1;
    &, & > ._map {
        @apply flex-1;
        //@apply absolute inset-0 z-10;
    }

    > ._option {
        top: 50px;
        right: 400px;
        @apply absolute z-20 flex;
        > .un-icon {
            @apply text-xl bg-white w-10 h-10 flex items-center justify-center border rounded shadow cursor-pointer hover:ring-2 hover:ring-blue-200 hover:border-blue-700;
            & + .un-icon {
                @apply ml-2;
            }

            &.-active {
                @apply bg-blue-600 border-blue-700 text-white ring-2 ring-blue-200;
            }
        }
    }
}


.dialog-follow-form {
    @apply border rounded p-3 mt-4 border-blue-400 bg-blue-50 flex items-start;
}

.dialog-follow-item {
    @apply border rounded p-2 border-orange-400 bg-white;
    + .dialog-follow-item {
        @apply mt-2;
    }

    ._footer {
        @apply flex text-sm;
        ._by {
            @apply text-grey-blue-500;
        }

        ._follow {
            @apply ml-auto text-red-500;
        }
    }

    .text-content p:last-child {
        @apply p-3 mb-0;
    }
}


.logaction-view {
    ._detail {
        @apply flex;
        ._header {
            @apply text-lg w-28 mb-2 text-grey-blue-500;
        }

        ._detail {
            @apply flex-1 text-lg;
        }
    }

    ._detail-vertical {
        ._header {
            @apply text-lg w-28 mb-2 text-grey-blue-500;
        }
    }

    ._detail-view {
        @apply flex-1 p-1 my-1 bg-grey-blue-50 rounded-md text-sm;
        > ._item {
            @apply flex hover:bg-grey-blue-100 rounded;
            > ._name {
                @apply w-24 text-grey-blue-500 text-right pr-2 truncate;
            }

            > ._text {
                @apply flex-1 whitespace-pre-wrap;
                word-break: break-all;
            }
        }
    }
}

.logaction-action {
    @apply ml-0 mr-1;
    &.-click {
        @apply cursor-pointer;
    }
}
