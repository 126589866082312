.link-click {
    @apply cursor-pointer hover:text-red-500;
    &.-blue {
        @apply text-blue-500 hover:text-red-500;
    }
}

.text-subtitle {
    @apply text-grey-blue-500 mb-1 text-sm;
}

.icon-move {
    @apply cursor-move hover:text-red-500;
}

.un-tag.-brown {
    @apply bg-brown-500;
}

.box {
    @apply relative border rounded-md px-3 pt-2 pb-3 mb-6;
    > ._label {
        @apply absolute text-sm -top-2.5 left-3 px-1 -mx-1 z-20 text-grey-600 leading-none rounded-md bg-white;
    }

    .un-inputbox {
        @apply mb-0;
    }
}

.info-active {
    @apply flex items-center;
    > ._name {
        @apply ml-2 mr-4;
    }
}


.un-select-day {
    @apply flex flex-1 border border-grey-200 mr-2 py-1 px-0.5 rounded overflow-hidden;
    max-width: 240px;

    > ._item {
        @apply flex flex-1 items-center justify-center mx-0.5 px-0 rounded text-sm text-grey-400 cursor-pointer hover:bg-grey-blue-50;
        &.-active {
            @apply bg-blue-500 text-white hover:bg-blue-600;
        }
    }
}


.title-dropdown {
    @apply px-3 py-1 cursor-pointer rounded hover:ring-2 hover:ring-orange-500 hover:ring-opacity-50 hover:bg-orange-50;
}

.check-3, .check-4 {
    ._controls {
        @apply flex-wrap;
        ._control-input-check {
            @apply w-1/4 ml-0 pb-0;
            ._control-input-icon {
                @apply py-0 leading-tight;
            }

            ._control-input-label {
                @apply py-0;
            }
        }
    }
}

.check-3 ._controls ._control-input-check {
    @apply w-1/3;
}

.un-modal-menubar {
    @apply -mt-4 -mx-4 mb-8;
}

.un-radio-option {
    .un-input-radio > ._controls {
        @apply flex-nowrap;
        ._control-input-label {
            @apply whitespace-nowrap;
        }
    }
}

.datatable-container {
    .-red {
        @apply bg-red-100;
    }
}

.un-dropdown.-in-modal {
    @apply z-40;
}

.mock {
    @apply border rounded-md text-4xl mb-8 text-center flex flex items-center justify-center h-96 text-grey-500 bg-grey-50;
}

.un-list-body > .mock {
    @apply h-auto py-8 m-2;
}
