@import url('https://fonts.googleapis.com/css2?family=Mali:wght@200;300;400;500;600;700&family=JetBrains+Mono:wght@400&display=swap');

.document-block, .document-content-menu {
    font-family: Mali, Tahoma, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.document-content {
    .un-modal-body-scroll {
        .simplebar-content {
            @apply flex items-start;
        }
    }
}

.document-content-menu {
    @apply flex flex-col w-80 px-2 py-4 rounded-md bg-grey-blue-50;
    ul {
        @apply pl-8 mb-0;
        li {
            @apply list-disc mb-2;
            &:last-child {
                @apply mb-0;
            }
        }
    }
}

.document-content-blocks {
    @apply flex flex-col flex-1 ml-4;
    > ._btn-add {
        @apply mt-auto pt-4 text-center;
    }

    > .document-block {
        > ._content {
            > ._title {
                @apply text-2xl leading-8;
            }
        }
    }
}

.document-block {
    @apply p-2 relative border border-white;

    &:hover {
        > ._option {
            @apply visible;
        }
    }

    &.-editable {
        &:hover {
            @apply rounded-md border border-orange-500 border-dashed;
        }

        > ._option {
            @apply bg-blue-500 rounded-full;
            .un-icon-button {
                @apply text-white hover:bg-blue-700;
            }
        }
    }

    &.-edit {
        @apply border border-blue-500 border-dashed rounded-lg hover:shadow-none;
        > ._icon-coll {
            @apply hidden;
        }

        > ._option {
            @apply visible;
            > ._icon-type {
                @apply block;
            }

            > ._icon-edit {
                @apply font-bold text-blue-500 bg-white hover:bg-blue-100;
                &:before {
                    content: "\f00d";
                }
            }
        }
    }

    &.-coll {
        > ._option {
            > ._icon-edit {
                @apply hidden;
            }
        }
    }

    > ._icon-coll {
        @apply absolute top-3 left-3 flex flex-col p-1 z-40;
    }

    > ._option {
        @apply absolute top-2 right-2 flex flex-row p-1 invisible z-40;
        > ._icon-type {
            @apply hidden;
        }
    }

    > ._content {
        @apply px-12;
        > ._title {
            @apply text-xl py-1 mb-2 leading-8;
        }

        > ._input-title {
            @apply mr-28;
        }

        > ._detail {
            @apply my-2 px-4;
            > ._form {
                @apply p-4 rounded-lg bg-blue-50;
                > ._btn-save {
                    @apply text-right;
                    .un-button {
                        @apply mb-0;
                    }
                }
            }
        }


        > ._children {
            @apply my-2;
        }

        .text-content {
            @apply text-lg;
        }
    }
}

.document-type-selector {
    @apply z-40 w-40 border-0 bg-grey-blue-800;
    > ._item {
        @apply py-1 px-3 m-0.5 text-white cursor-pointer;
        &:hover {
            @apply bg-grey-blue-700;
        }

        > .un-icon {
            @apply w-8;
        }
    }
}

.document-title-sort {
    @apply flex flex-1 px-1 rounded-md cursor-move truncate;
    &.-landing {
        @apply bg-blue-200;
    }

    > ._title {
        @apply flex flex-1 cursor-pointer my-auto text-grey-blue-700 truncate;
        &.-none {
            @apply italic text-grey-blue-300;
        }

        &:hover {
            @apply text-red-500;
        }
    }
}

.document-title-sort-collapse {
    @apply absolute -left-8 -top-1;
}


.document-block-youtube {
    @apply py-4;
    iframe {
        @apply mx-auto border rounded p-2 shadow-xl;
        width: 640px;
        height: 385px;
    }
}

.document-block-file {
    @apply flex flex-wrap;
    > ._item {
        @apply flex border rounded-md py-1 px-2 m-2 cursor-pointer hover:border-orange-500 hover:shadow;
        > .un-icon {
            @apply w-6 mr-2 text-center;
        }

        > ._name {
            @apply w-40 truncate;
        }

        > ._size {
            @apply w-16 text-right text-orange-500;
        }
    }
}


.rst__rowContents,
.rst__rowLabel,
.rst__toolbarButton {
    display: inline-block;
    vertical-align: middle;
}

.rst__rowContents {
    position: relative;
    height: 100%;
    padding: 0 5px 0 10px;
    border-radius: 2px;
    min-width: 230px;
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
}

.rst__node {
    min-width: 100%;
    white-space: nowrap;
    position: relative;
    text-align: left;
}

.rst__nodeContent {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
}
