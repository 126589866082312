
.schedule-calendar {
    @apply border;
    ._row {
        @apply flex;
        &._head {
            @apply text-center text-sm bg-grey-blue-50;
        }

        &._data {
            > ._box {
                @apply border-t p-2 cursor-pointer hover:bg-orange-100;

                &.-selected {
                    @apply bg-blue-200;
                }

                &.-sum {
                    @apply flex items-center;
                    ._day {
                        @apply w-8 text-center text-grey-blue-500;
                    }

                    ._count {
                        @apply py-0.5;
                    }
                }

                ._header {
                    @apply flex;
                    ._date {
                        @apply text-sm cursor-pointer;
                    }
                }

                ._count {
                    @apply ml-auto text-sm py-0 px-2 text-white bg-blue-500 rounded transition-colors;
                }

                ._user {
                    @apply mt-2 flex flex-wrap -mx-1;
                    > .un-button {
                        @apply py-0.5 px-0 text-xs;
                        width: 47.9%;
                        margin: 1%;
                    }
                }
            }
        }

        > div {
            @apply flex-1 border-l py-1;
            &:first-child {
                @apply border-l-0;
            }
        }
    }
}

.schedule-calendar3 {
    @apply border rounded;

    > ._row {
        @apply flex;
        > ._month {
            @apply w-24 border-r mr-0.5 px-2 text-sm;
        }

        > ._item {
            @apply flex-1 m-px text-sm text-center rounded;
        }
    }

    > ._row.-header {
        @apply border-b;
        > ._item {
            &.-day_0 {
                @apply bg-red-500 text-white;
            }

            &.-day_6 {
                @apply bg-purple-500 text-white;
            }
        }
    }


    > ._row {
        &.-tomonth {
            @apply bg-orange-50;
            > ._month {
                @apply text-orange-700;
            }

            > ._item.-day {
                @apply bg-orange-100;
                &.-has {
                    @apply bg-orange-200;
                    > ._count {
                        @apply bg-orange-700;
                        &.-status {
                            @apply bg-orange-100;
                        }
                    }
                }

                &.-select {
                    @apply bg-blue-500;
                    > ._day {
                        @apply text-white;
                    }

                    &.-has {
                        > ._count {
                            @apply bg-blue-700;
                        }
                    }
                }

                &.-today {
                    @apply ring-2 ring-orange-500 ring-opacity-50;
                }

                > ._day {
                    @apply text-orange-700;
                }
            }
        }

        > ._item.-day {
            @apply bg-grey-blue-50 cursor-pointer hover:bg-blue-200;
            &.-has {
                > ._count {
                    @apply text-sm bg-grey-blue-400 rounded text-white;
                }
            }

            > ._day {
                @apply text-xs;
                > ._label {
                    @apply hidden;
                }
            }

            > ._count {
                @apply text-sm m-px;
                &.-status {
                    @apply bg-grey-blue-100;
                    ._n {
                        @apply hidden;
                    }
                }
            }
        }
    }
}

.schedule-table {
    @apply relative border rounded;
    ._row {
        @apply flex border-t;
        ._car {
            @apply w-48;
        }

        ._car-detail {
            @apply relative py-1 px-2;
            min-height: 2rem;

            .btn-add {
                @apply absolute right-0 top-0;
            }
        }

        ._hour {
            @apply flex flex-1;
            > ._h {
                @apply flex-1;
                &:before {
                    @apply absolute block inset-y-0 border-l border-dashed z-10;
                    content: "";
                }
            }
        }

        ._item {
            @apply flex-1;
        }
    }

    ._header {
        @apply text-center py-1 text-grey-500 border-t-0;
    }
}

.schedule-slots {
    @apply relative h-6 my-1 bg-blue-100;

    &._hover {
        @apply bg-blue-200;
    }
}

.schedule-slot-item {

    @apply absolute h-6 text-center text-white text-xs leading-6 z-10 cursor-default truncate rounded;
    //z-index: 1000;
    &.-backday {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 3px dotted white;
    }

    &.-nextday {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 3px dotted white;
    }

    &.-type0 {
        background-color: wcolor($grey, 80%);

        &:hover {
            z-index: 1001;
            background-image: linear-gradient(to bottom, wcolor($grey, 60%) 0px, $grey 100%);
            background-repeat: repeat-x;
            background-color: $grey;
        }

        &:active {
            background-image: linear-gradient(to bottom, $grey 0px, wcolor($grey, 80%) 100%);
            background-repeat: repeat-x;
            background-color: $grey;
        }
    }

    &.-type10 {
        background-color: wcolor($blue, 80%);

        &:hover {
            z-index: 1001;
            background-image: linear-gradient(to bottom, wcolor($blue, 60%) 0px, $blue 100%);
            background-repeat: repeat-x;
            background-color: $blue;
        }

        &:active {
            background-image: linear-gradient(to bottom, $blue 0px, wcolor($blue, 80%) 100%);
            background-repeat: repeat-x;
            background-color: $blue;
        }
    }

    &.-type20 {
        background-color: wcolor($purple, 80%);

        &:hover {
            z-index: 1001;
            background-image: linear-gradient(to bottom, wcolor($purple, 60%) 0px, $purple 100%);
            background-repeat: repeat-x;
            background-color: $purple;
        }

        &:active {
            background-image: linear-gradient(to bottom, $purple 0px, wcolor($purple, 80%) 100%);
            background-repeat: repeat-x;
            background-color: $purple;
        }
    }

    > .ui-resizable-handle {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 5px;
        cursor: w-resize;

        &.ui-resizable-w {
            left: 0;
        }

        &.ui-resizable-e {
            right: 0;
        }
    }
}

.schedule-slot-detail {
    @apply relative flex mx-px bg-white text-sm hover:bg-orange-50 z-20;
    > ._time {
        @apply w-24 text-right font-bold text-grey-blue-500;
    }

    > ._info {
        @apply flex-1 ml-2;
        > ._name {
            @apply cursor-pointer hover:text-red-500 hover:underline;
        }

        > ._flag {
            @apply flex items-center flex-wrap;
        }
    }
}

.schedule-item {
    @apply relative flex items-start border rounded mb-3 p-1 hover:shadow;

    > ._rownum {
        @apply absolute text-grey-blue-500 bg-grey-blue-50 text-sm px-1 rounded top-0.5 left-0.5;
    }

    > .un-icon-button {
        @apply w-16 h-16 mr-2 text-4xl;
    }

    > ._detail {
        @apply flex-1;
        > ._head {
            > ._name {
                @apply text-lg font-bold mr-4;
            }

            > ._time {
                @apply text-red-500 mr-4;
            }

            > ._user {
                @apply text-green-500 mr-4;
            }
        }

        > ._text {
            @apply text-sm text-grey-blue-600;
        }
    }

    > ._sign {
        @apply w-60 h-16 rounded border ml-2 cursor-pointer shadow-inner bg-white bg-center bg-cover hover:border-orange-500;
    }
}

.schedule-list-detail {
    @apply overflow-hidden whitespace-pre-wrap hover:max-h-full;
    max-height: 1rem;
}

.schedule-doc-container {
    @apply border rounded pt-2;
    > ._body {
        @apply p-3;
    }
}

.schedule-doc-order-check {
    @apply flex;
    > ._label {
        @apply w-24 pt-2 my-px text-grey-blue-500;
    }

    > ._check {
        @apply flex flex-1;
    }
}

.schedule-doc-service-after {
    @apply flex;
    > ._label {
        @apply w-24 pt-2 my-px mr-4 text-right text-grey-blue-500;
    }
}

.schedule-signature {
    @apply mx-auto mt-6 border p-2 rounded-md w-1/3;
    width: 500px;

    > ._head {
        @apply flex items-center;
        > ._label {
            @apply flex-1 text-lg text-grey-blue-500;
        }
    }

    > ._canvas {
        @apply bg-grey-blue-50 w-full rounded-md mt-3;
    }

    > ._date {
        @apply mt-4 flex items-center justify-center;
        > ._label {
            @apply text-xl text-grey-blue-500 mr-4;
        }
    }
}


.schedule-work-money {
    @apply flex items-center border bg-green-50 border-green-500 rounded px-2 -mt-2;
    margin-left: auto !important;

    &.-list {
        @apply mt-0 py-1;
    }

    ._label {
        @apply mr-2;
    }

    ._value {
        @apply text-xl;
    }
}

.schedule-form {
}

.schedule-form-using {
    @apply bg-red-100;
    .un-modal-header {
        @apply bg-red-200;
    }

    .un-modal-body {
        @apply bg-white rounded-md;
    }
}

.schedule-form-using-by {
    @apply flex  bg-red-50 border border-red-500 -mt-2 mb-2 -mx-2 py-2 px-4 rounded;
    > ._header {
        @apply mr-2 text-red-400;
    }

    > ._user {
        @apply font-bold text-red-600;
        > ._name + ._name {
            @apply ml-2;
        }
    }
}

.schedule-view-item {
    @apply border rounded flex items-center p-3;

    + .schedule-view-item {
        @apply mt-4;
    }

    > .un-icon {
        @apply text-3xl mr-4;
    }

    > ._text {
        @apply flex-1 text-xl;
        .link-tel {
            @apply text-lg;
            > .fa {
                @apply mr-2;
            }
        }
    }

    @each $c in $color-names {
        &.-#{$c} {
            @apply border-#{$c}-500 bg-#{$c}-50 hover:bg-#{$c}-100;
            > .un-icon {
                @apply text-#{$c}-400;
            }
        }
    }
}

.schedule-view-item-detail {
    @apply mt-4 flex items-start;
}

.schedule-view-item-file {
    > ._head {
        @apply text-grey-blue-500 mb-2 -mt-2;
    }

    a {
        @apply text-xl px-4 py-2 hover:text-red-500;
        > .un-icon {
            @apply text-2xl;
        }
    }
}

.schedule-view-item-file {
    @apply flex-1 mr-4;
}

.schedule-view-detail {
    @apply border shadow-inner rounded mt-4 p-6 text-lg;
}


.schedule-copy-info {
    @apply border border-orange-500 bg-orange-50 rounded mb-6 py-1 px-3;
}


.onsite-order-header {
    margin-left: 0 !important;
    margin-right: 0 !important;

    > ._grid {
        @apply flex;
        > div {
            @apply px-2 py-2;
            &.onsite-order-bf, &.onsite-order-af {
                @apply w-10;
            }
        }
    }
}

.onsite-order-bf {
    @apply bg-orange-50;
}

.onsite-order-af {
    @apply bg-green-50;
}

.text-v {
    @apply text-black;
    writing-mode: vertical-rl;
    text-orientation: sideways;
    transform: rotate(-180deg);
}


.onsite-title {
    @apply flex items-center mb-4;

    > .un-icon {
        @apply text-3xl font-bold w-12 h-12;
    }

    > ._title {
        @apply text-2xl ml-2;
    }
}

.onsite-map {
    @apply mb-6 rounded border;
}

////////////////////

.tms_stock-box {
    @apply rounded-lg;

    > .form-label {
        font-weight: bold;
        color: $grey;
    }

    &.-green {
        background-color: wcolor($green, 5%);
        border-color: wcolor($green, 50%);

        > .form-label {
            color: $green;
        }
    }

    &.-orange {
        background-color: wcolor($orange, 5%);
        border-color: wcolor($orange, 50%);

        > .form-label {
            color: $orange;
        }
    }
}

.stockdoc-info {
    display: block;

    ._stock {
        color: $green;
        font-weight: bold;
    }

    ._number, ._date {
        color: $red;
        font-weight: bold;
        font-size: 10pt;
    }

    ._title {
        display: block;
        font-size: .9em;
        color: $blue;
    }

    ._note {
        display: block;
        font-size: .8em;
        color: $grey;
    }
}
