.tracking-container {
    @apply relative flex-1 -m-4 w-auto h-auto;
}

.tracking-resizer.Resizer.vertical {
    background: $grey-blue;
    transition: all .2s ease;
    width: 10px;
    margin: 0 -3px;
    border-left: 3px solid $white;
    border-right: 3px solid $white;

    &:hover {
        opacity: 0.8;
        background: $blue;
    }
}


.tracking-side {
    width: 100%;
    display: flex;
    flex-direction: column;

    &.-sm {
        .tracking-tab {
            > ._item {
                padding: .25rem .75rem;

                > span {
                    display: none;
                }
            }
        }

    }
}

.tracking-subcontent {
    border-top: 2px solid wcolor($grey-blue, 50%);
    flex: 1;
    display: flex;
    flex-direction: column;

    > ._content {
        padding: .5rem;
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .detail-item {
        ._label {
            width: 100px;
            min-width: 100px;
        }
    }
}

.tracking-map {
    flex: 1;
    display: flex;
    flex-direction: column;

    > .btn-show-side {
        position: absolute;
        top: 0;
        left: 0;
    }

    > ._map {
        position: relative;
        flex: 1;
    }

    > ._streetview {
        height: 50%;
        position: relative;

        > .un-button {
            position: absolute;
            bottom: .5rem;
            left: .5rem;
            border-radius: .125rem;
            background-color: $white;

            &:hover {
                background-color: wcolor($grey-blue, 25%);
            }
        }

        > iframe {
            height: 100%;
            width: 100%;
            border: none;
        }
    }
}

.tracking-tab {
    @apply flex pl-4 bg-grey-blue-100 border-b border-grey-blue-300;

    > ._item {
        @apply mt-1 px-2.5 py-1 rounded-t cursor-pointer;
        &.-active {
            @apply border border-grey-blue-200 bg-white;
            border-bottom-color: transparent;
            margin-bottom: -1px;
        }

        &:hover {
            background-color: wcolor($grey-blue, 35%);
        }

        .un-icon {
            @apply w-6;
        }
    }

    > .un-button {
        @apply my-auto ml-auto;
    }
}

.tracking-search {
    padding: .25rem;
    display: flex;
    align-items: center;

    .dropdown-toggle > .un-icon {
        font-size: 1.25rem;
        color: $grey-blue;

        &:hover {
            color: $red;
        }
    }
}

.tracking-history {
    @apply p-1;
    > ._row {
        @apply flex;
        align-items: center;

        + ._row {
            @apply mt-1;
        }

        .un-input-date {
            @apply flex-1;
            + .un-input-select {
                @apply flex-1 ml-2;
            }
        }
    }

    .un-inputbox, .un-button {
        @apply mb-0;
    }

    .un-input-date ._control {
        @apply pl-0;
    }

    .un-button {
        @apply px-3;
    }
}

.tracking-history-speed {
    .un-dropdown-menu {
        @apply w-20;
    }

    .un-dropdown-item {
        @apply min-w-0;
    }
}


.tracking-notify {
    padding: .25rem;
    display: flex;
}

.tracking-table-container {
    flex: 1;
    position: relative;
}

.tracking-table {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    overflow: auto;

    > ._thead {
        position: sticky;
        top: 0;

        ._tr {
            display: flex;
            background-color: wcolor($grey-blue, 15%);
            border-bottom: 2px solid wcolor($grey-blue, 50%);

            ._th {
                @apply truncate;
                border-left: 1px solid wcolor($grey-blue, 30%);
                font-size: 9pt;
                padding: .25rem 0;
                text-align: center;
                position: relative;

                &:hover {
                    > ._resizer {
                        display: block;
                    }
                }

                > ._resizer {
                    display: none;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    width: 3px;
                    background-color: rgba($grey-blue, .8);

                    &.-ing, &:hover {
                        display: block;
                        background-color: rgba($red, .8);
                    }
                }
            }
        }
    }

    > ._tbody {
        ._tr {
            display: flex;
            cursor: default;

            &:hover {
                background-color: wcolor($yellow, 10%);
            }

            &.-select {
                background-color: wcolor($yellow, 50%);
            }


            &.-group {
                background-color: wcolor($grey-blue, 75%);
                color: $white;

                ._cell {
                    padding: .125rem .25rem;
                    border-left: 1px solid wcolor($grey-blue, 65%);
                }
            }

            ._cell {
                @apply truncate;
                padding: .25rem 0;
                border-left: 1px solid wcolor($grey-blue, 15%);
                border-bottom: 1px solid wcolor($grey-blue, 15%);;
                text-align: center;

                &.-device, &.-poi, &.-name, &.-detail, &.-location {
                    text-align: left;
                    padding-left: .25rem;
                }

                &.-time {
                    text-align: right;
                    padding-right: .25rem;
                }

                &.-checkbox {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .tracking-device-status {
                    color: $white;
                    display: block;
                    margin: 0 .25rem;
                    border-radius: .25rem;
                }
            }
        }
    }
}

.tracking-device-header {
    @apply flex items-start p-2 mb-2 bg-grey-blue-50 rounded;

    .tracking-device-status {
        @apply flex flex-col w-16 h-12 mr-2 text-lg text-white items-center justify-center rounded;

        > ._speed {
            @apply text-lg leading-none font-bold;
        }

        > ._unit {
            @apply text-xs;
        }
    }

    > ._text {
        @apply flex-1;

        > ._name {
            @apply text-lg leading-tight;
        }

        > ._detail {
            @apply text-grey-blue-500;
        }
    }

    > ._number {
        @apply py-0.5 px-2 rounded text-red-500 border border-red-500 bg-yellow-500;
    }
}

.tracking-device-box {
    @apply flex;

    .detail-item {
        @apply flex-1 flex text-center items-center p-1 border rounded;
        + .detail-item {
            @apply ml-2;
        }

        &.-sm {
            flex: 1;
        }

        &.-md {
            flex: 2;
        }

        > ._label {
            text-align: inherit;
            padding: 0;
            width: auto;
            min-width: 0;
            flex: 1;
        }

        > ._value {
            padding: 0 1rem;
            flex-grow: 0;
        }
    }
}

.tracking-device-photo {
    width: 100px;
    margin-right: .5rem;
    display: flex;

    &:hover {
        > ._input-container {
            border-color: $blue;
        }
    }

    &.input-photo.-single > ._input-container {
        width: auto;
        flex: 1;
    }

    &.input-photo ._item {
        display: flex;
        flex: 1;

        > ._photo {
            width: auto;
            height: auto;
            flex: 1;
            border: none;
            background-color: $white;

            > .un-icon {
                display: none;
            }
        }
    }
}

.tracking-device-status {
    background-color: wcolor($grey-blue, 75%);

    &.-status-1 {
        background-color: $blue;
    }

    &.-status-2 {
        background-color: $green;
    }

    &.-status-3 {
        background-color: $red;
    }
}


.tracking-poi-header {
    padding: .5rem;
    margin-bottom: .5rem;
    background-color: wcolor($grey-blue, 10%);
    border-radius: .25rem;
    display: flex;
    align-items: center;

    > .mi {
        margin: 0 .25rem;
        font-size: 1.5rem;
    }

    > ._text {
        flex: 1;
        margin-left: .5rem;

        > ._name {
            font-size: 16pt;
            line-height: 24px;
        }

        > ._detail {
            color: $grey-blue;
        }
    }
}


.tracking-map-popup {
    @apply relative h-px;
    width: 320px;

    * {
        box-sizing: border-box !important;
    }

    &.-marker-sm {
        > ._container {
            bottom: 3rem;
        }
    }

    > ._container {
        @apply absolute inset-x-0 border rounded-lg shadow-lg bg-white p-2;
        bottom: 4.5rem;
        min-height: 50px;

        &:before {
            // layout
            content: '';
            @apply absolute w-0 h-0;
            top: 100%;
            left: 50%; // offset should move with padding of parent
            margin-left: -12px;
            border: .75rem solid transparent;
            border-bottom: none;

            // looks
            border-top-color: #fff;
        }


        > ._header {
            @apply flex items-center;
            > ._title {
                @apply flex-1 truncate;

                > ._text {
                    @apply text-base leading-none truncate;
                }

                > ._detail {
                    @apply text-xs text-grey-blue-500;
                }
            }

            .tracking-device-status {
                @apply flex flex-col w-12 py-1 text-xl text-white mr-2 rounded items-center justify-center;
                > ._speed {
                    font-size: 14pt;
                    font-weight: bold;
                    line-height: 1rem;
                }

                > ._unit {
                    font-size: 7pt;
                    line-height: .75rem;
                }
            }
        }

        > ._body {
            @apply border rounded;
            padding: .25rem;
            margin-top: .5rem;
        }
    }
}

.tracking-notify-setting {
    .un-input-checkbox-item {
        @apply mr-0 w-1/2;
    }
}


.device-forward-dropdown {
    @apply overflow-y-auto;
    max-height: 300px;

    .un-dropdown-item {
        @apply block truncate;
        width: 240px;
    }
}


.poi-form {
    @apply flex;
}

.poi-form-map {
    @apply relative flex-1 rounded-lg overflow-hidden;
}

.poi-form-control {
    @apply ml-4;
    width: 300px;

    .marker-form {
        @apply w-auto max-w-none min-w-0 mb-8;
        .marker-preview-box {
            @apply hidden;
        }

        .marker-icon-box {
            @apply mt-0;
        }
    }
}



.ldmap_placeholder .ldmap_topright {
    &.ldmap_maparea {
        @apply flex;
    }
}

._map .ldmap_placeholder .ldmap_contextmenu {
    @apply rounded-md overflow-hidden border-green-500 ring-4 ring-green-400 ring-opacity-20;

    .ldmap_contextmenu_info {
        @apply bg-green-100 text-xs;
        > div {
            > div, > hr {
                @apply hidden;
            }
        }
    }

    .ldmap_contextmenu_location {
        @apply mt-2 text-right text-xs text-green-500;
    }

    .ldmap_contextmenu_extra {
        @apply flex justify-between bg-green-500;
    }

    .ldmap_contextmenu_item {
        @apply cursor-pointer text-green-50 hover:text-green-200;
    }

    .ldmap_link {
        @apply cursor-pointer text-green-50 hover:text-green-200;
    }
}